import { colors } from "../styles/colors";

export function getStudentNavbarSelected(location, currentIteration) {
  if (location.pathname === "/student-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (
    (location.pathname === "/student-search-job" ||
      location.pathname.includes("/student-job-detail")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-upskilling" ||
      location.pathname.includes("/student-match-profile")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-community" ||
      location.pathname.includes("/student-community")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/student-allcommunity" ||
      location.pathname.includes("/student-allcommunity")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    location.pathname === "/student-myapplication" &&
    currentIteration.id === 4
  ) {
    return true;
  }
  if (
    location.pathname === "/student-favourites" &&
    currentIteration.id === 5
  ) {
    return true;
  }
  if (
    location.pathname === "/student-notifications" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  return false;
}

export function getStudentBreadcrumbs(location) {
  if (location.pathname === "/student-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-search-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Search Job/Internship",
        path: "/student-search-job",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/student-job-detail")) {
    let jobTitle = "";
    if (location && location.state && location.state.title) {
      jobTitle = location.state.title;
    }
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Search Job/Internship",
        path: "/student-search-job",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: jobTitle,
        path: "",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-upskilling") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/student-match-profile")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Upskilling",
        path: "/student-upskilling",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Match Profile",
        path: "",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/student-community")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-community",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/student-allcommunity")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Community Engagement",
        path: "/student-community",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Community Engagement",
        path: "/student-community",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "My Profile",
        path: "/student-profile",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-myapplication") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "My Application",
        path: "/student-myapplication",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-favourites") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Favourites",
        path: "/student-favourites",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/student-notifications") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Notification",
        path: "/student-notifications",
        color: colors.primary,
      },
    ];
  }
  return [];
}

export function getEmployerNavbarSelected(location, currentIteration) {
  if (
    location.pathname === "/employer-dashboard" &&
    currentIteration.id === 0
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-search-job" &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/employer-internship" ||
      location.pathname.includes("/employer-internship")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/employer-intern-registration" ||
      location.pathname.includes("/employer-intern-registration")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-applied-candidates" &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/employer-college-connect" ||
      location.pathname.includes("/employer-college-list") ||
      location.pathname.includes("/employer-college-connect-students-list")) &&
    currentIteration.id === 5
  ) {
    return true;
  }
  if (
    location.pathname === "/employer-search-candidates" &&
    currentIteration.id === 4
  ) {
    return true;
  }
  return false;
}

export function getEmployerBreadcrumbs(location) {
  if (location.pathname === "/company-profile") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "My Profile",
        path: "/company-profile",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-internship") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Internship",
        path: "/employer-internship",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname.includes("/employer-intern-registration")) {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Intership",
        path: "/employer-internship",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Post Internship",
        path: "/employer-intern-registration",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-applied-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Applied Candidates",
        path: "/employer-applied-candidates",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-college-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-college-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-college-connect-students-list") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "College Connect",
        path: "/employer-college-connect",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "A M Jain College of Arts and Science",
        path: "/employer-college-list",
        color: "#A4A5A8",
      },
      {
        id: 4,
        name: "Students List",
        path: "/employer-college-connect-students-list",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-search-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-search-job",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-add-job") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-search-job",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Post Job",
        path: "/employer-add-job",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-job-draft") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-search-job",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Draft",
        path: "/employer-job-draft",
        color: colors.primary,
      },
    ];
  }

  if (location.pathname === "/employer-job-appliedcanditate") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Jobs",
        path: "/employer-search-job",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Job Title",
        path: "/employer-job-view-details",
        color: "#A4A5A8",
      },
      {
        id: 4,
        name: "Applied Candidate",
        path: "/employer-job-appliedcanditate",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/employer-search-candidates") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/employer-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Search Candidates",
        path: "/employer-search-candidates",
        color: colors.primary,
      },
    ];
  }
  return [];
}

export function getCampusNavBarSelected(location, currentIteration) {
  if (location.pathname === "/campus-dashboard" && currentIteration.id === 0) {
    return true;
  }
  if (
    (location.pathname === "/campus-students" ||
      location.pathname.includes("/campus-students")) &&
    currentIteration.id === 1
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-connect" ||
      location.pathname.includes("/campus-industry-connect")) &&
    currentIteration.id === 2
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-faculty-development" ||
      location.pathname.includes("/campus-faculty-development")) &&
    currentIteration.id === 3
  ) {
    return true;
  }
  if (
    (location.pathname === "/campus-industry-immersion-program" ||
      location.pathname.includes("/campus-industry-immersion-program")) &&
    currentIteration.id === 4
  ) {
    return true;
  }

  if (
    (location.pathname === "/campus-industry-consulting-project" ||
      location.pathname.includes("/campus-industry-consulting-project")) &&
    currentIteration.id === 5
  ) {
    return true;
  }

  return false;
}

export function getCampusFLowBreadcrumbs(location) {
  if (location.pathname === "/campus-dashboard") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/campus-dashboard",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/campus-students") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Students",
        path: "/campus-students",
        color: colors.primary,
      },
    ];
  }
  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: colors.primary,
      },
    ];
  }

  if (location.pathname === "/campus-industry-connect") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Industry Connect",
        path: "/campus-industry-connect",
        color: "#A4A5A8",
      },
      {
        id: 3,
        name: "Posted Jobs",
        path: "/campus-industry-connect/posted-jobs",
        color: colors.primary,
      },
    ];
  }

  if (location.pathname === "/campus-faculty-development") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Faculty Development",
        path: "/campus-faculty-development",
        color: colors.primary,
      },
    ];
  }

  if (location.pathname === "/campus-industry-immersion-program") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Industry Immersion Program",
        path: "/campus-industry-immersion-program",
        color: colors.primary,
      },
    ];
  }

  if (location.pathname === "/campus-industry-consulting-project") {
    return [
      {
        id: 1,
        name: "Dashboard",
        path: "/student-dashboard",
        color: "#A4A5A8",
      },
      {
        id: 2,
        name: "Industry Consulting Project",
        path: "/campus-industry-consulting-project",
        color: colors.primary,
      },
    ];
  }
  return [];
}
