const images = {
  login_image: require("./login-image.svg").default,
  employee_symbol: require("./employee_symbol.svg").default,
  homepage_carousel_1: require("./homepage-carousel1.svg").default,
  homepage_carousel_2: require("./homepage-carousel2.svg").default,
  homepage_carousel_3: require("./homepage-carousel3.svg").default,
  otp_verification: require("./otp-verification.svg").default,
  circle_tick: require("./circle_tick.svg").default,
  // Locations
  chennai: require("./chennai.svg").default,
  mumbai: require("./mumbai.svg").default,
  pune: require("./pune.svg").default,
  kolkata: require("./kolkata.svg").default,
  hyderabad: require("./hyderabad.svg").default,
  bangalore: require("./bangalore.svg").default,
  // Jobs
  marketing: require("./marketing.svg").default,
  design: require("./web-design.svg").default,
  hrd: require("./hrd.svg").default,
  finance: require("./finance-management.svg").default,
  part_time_jobs: require("./part-time.svg").default,
  business_consulation: require("./business-consultation.svg").default,
  customer_support: require("./customer-support.svg").default,
  project_management: require("./project-management.svg").default,
  student_dashboard_hello: require("./student-dashboard-hello.svg").default,
  // Skills
  code: require("./code.svg").default,
  code_2: require("./code-2.svg").default,
  coding: require("./coding.svg").default,
  sql: require("./sql.svg").default,
  creative_writing: require("./creative-writing.svg").default,
  digital_marketing: require("./digital-marketing.svg").default,
  excel: require("./excel.svg").default,
  hacker: require("./hacker.svg").default,
  handshake: require("./handshake.svg").default,
  machine_learning: require("./machine-learning.svg").default,
  //Feedback
  meeting: require("./meeting.svg").default,
  office: require("./office.svg").default,
  popup: require("./popup.svg").default,
  profilepic: require("./profilepic.svg").default,
  python: require("./python.svg").default,
  quotes: require("./quotes.svg").default,
  quotes2: require("./quotes2.svg").default,
  //Recomended
  devops: require("./devops.svg").default,
  web_dev: require("./web-dev.svg").default,
  data_analyst: require("./data-analyst.svg").default,
  product_manager: require("./product-manager.svg").default,
  fe_developer: require("./fe-developer.svg").default,
  ai_engineer: require("./ai-developer.svg").default,
  //no results
  no_result: require("./no-results-found.svg").default,
  no_saved_results: require("./no-saved-results.svg").default,
  //icon
  share: require("./share-icon.svg").default,
  like: require("./like.svg").default,
  job_image: require("./job_image.svg").default,
  Testimonal: require("./Testimonal.svg").default,
  separator: require("./separator.svg").default,
  remove_icon: require("./remove-icon.svg").default,

  // footer icons
  twitter: require("./twitter.svg").default,
  youtube: require("./youtube.svg").default,
  instagram: require("./instagram.svg").default,
  facebook: require("./facebook.svg").default,
  cielLogo: require("./cielLogo.svg").default,
  mafoiLogo: require("./mafoi.svg").default,
  IntegrumLogo: require("./Integrum.svg").default,

  companyC: require("./companyCo.svg").default,
  line: require("./line.svg").default,
  intership_image: require("./internship_image.svg").default,
  pdf_icon: require("./pdf-icon.svg").default,
  mp4_icon: require("./mp4-icon.svg").default,

  // Student flow
  successTick: require("./SuccessTick.svg").default,

  // campus flow
  campusBannerLogo: require("./campusBannerLogo.svg").default,
  campusGraph: require("./campusGraph.svg").default,
  companyIconK: require("./companyIconK.svg").default,
  NoIndustry: require("./NoIndustry.svg").default,
  pdfImage: require("./pdfImage.svg").default,

  // community
  shareIcon: require("./shareIcon.svg").default,
  commentIcon: require("./commentIcon.svg").default,

  //mock images temporary
  discussion1mock: require("./discussion1mock.svg").default,
  discussion2mock: require("./discussion2mock.svg").default,

  //Employer Internship
  personApplicants: require("./person-Application.svg").default,

  employer_dashboard_hello: require("./employer-dashboard-hello.svg").default,

  //Modals images
  alert: require("./alert.svg").default,
  empty: require("./emptyState.svg").default,
  logout: require("./logout.svg").default,
};

export default images;
