/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, HashRouter } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { HomePage } from "./pages/HomePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";

// Student Flow
import { StudentDashboard } from "./pages/StudentDashboard/Loadable";
import { Upskilling } from "./pages/StudentUpskilling/Loadable";
import { StudentSearchJob } from "./pages/StudentSearchJob/Loadable";
import { StudentJobDetail } from "./pages/StudentJobDetail/Loadable";
import { StudentProfile } from "./pages/StudentProfile/Loadable";
import { StudentCommunity } from "./pages/StudentCommunity/Loadable";
import { StudentAllCommunity } from "./pages/StudentAllCommunity/Loadable";
import { StudentMyApplication } from "./pages/StudentMyApplications/Loadable";
import { StudentFavourites } from "./pages/StudentFavourites/Loadable";
import { StudentNotifications } from "./pages/StudentNotifications/Loadable";

// Campus Flow
import { CampusDashboard } from "./pages/CampusDashboard/Loadable";
import { CampusStudents } from "./pages/CampusStudents/Loadable";
import { CampusIndustryConnect } from "./pages/CampusIndustryConnect/Loadable";
import { CampusFacultyDevelopment } from "./pages/CampusFacultyDevelopment/Loadable";
import { CampusIndustryImmersionProgram } from "./pages/CampusIndustryImmersionProgram/Loadable";
import { CampusIndustryConsultingProject } from "./pages/CampusIndustryConsultingProject/Loadable";

// Employer Flow
import { CompanyProfile } from "./pages/CompanyProfile/Loadable";
import { EmployerDashBoard } from "./pages/EmployerDashboard/Loadable";
import { AddEmployerInternshipDetails } from "./pages/EmployerAddIntership/Loadable";
import { MatchProfile } from "./pages/StudentMatchProfile/Loadable";
import { EmployerInternshipDetails } from "./pages/EmployerInternshipDetails/Loadable";
import { ViewInternshipDetails } from "./pages/EmployerViewInternshipDetails/Loadable";
import { EmployerAppliedCandidates } from "./pages/EmployerAppliedCandidates/Loadable";
import { EmployerCollegeConnect } from "./pages/EmployerCollegeConnect/Loadable";
import { EmployerCollegeStudentList } from "./pages/EmployerCollegeStudentList/Loadable";
import { EmployerCollegeList } from "./pages/EmployerCollegeList/Loadable";
import { AddEmployerJobs } from "./pages/EmployerAddJobs/Loadable";
import { EmployerJobDetails } from "./pages/EmployerJobsDetails/Loadable";
import { EmployerJobDraft } from "./pages/EmployerJobDraft/Loadable";
import { EmployerJobViewDetails } from "./pages/EmployerJobDetails";
import { EmployerJobAppliedCandidate } from "./pages/EmployerJobAppliedCandidates/Loadable";
import { EmployerSearchCandidates } from "./pages/EmployerSearchCandidates/Loadable";

import AppApolloprovider from "./ApolloProvider";
import { useTranslation } from "react-i18next";

export function App() {
  const { i18n } = useTranslation();
  return (
    <HashRouter>
      <Helmet
        // titleTemplate="%s - React Boilerplate"
        defaultTitle="Campus Connnect"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <AppApolloprovider>
        <Switch>
          <Route exact path="/" component={HomePage} />
          {/* Student Flow */}
          <Route exact path="/student-dashboard" component={StudentDashboard} />
          <Route
            exact
            path="/student-search-job"
            component={StudentSearchJob}
          />
          <Route
            exact
            path="/student-job-detail/:jobId"
            component={StudentJobDetail}
          />
          <Route exact path="/student-upskilling" component={Upskilling} />
          <Route
            exact
            path="/student-match-profile/:jobId"
            component={MatchProfile}
          />
          <Route exact path="/student-profile" component={StudentProfile} />
          <Route exact path="/student-community" component={StudentCommunity} />
          <Route
            exact
            path="/student-allcommunity"
            component={StudentAllCommunity}
          />
          <Route
            exact
            path="/student-myapplication"
            component={StudentMyApplication}
          />
          <Route
            exact
            path="/student-favourites"
            component={StudentFavourites}
          />
          <Route
            exact
            path="/student-notifications"
            component={StudentNotifications}
          />

          {/* Employer Flow */}
          <Route exact path="/company-profile" component={CompanyProfile} />
          <Route
            exact
            path="/employer-intern-registration"
            component={AddEmployerInternshipDetails}
          />
          <Route
            exact
            path="/employer-dashboard"
            component={EmployerDashBoard}
          />
          <Route
            exact
            path="/employer-internship"
            component={EmployerInternshipDetails}
          />
          <Route
            exact
            path="/employer-internshipdetails"
            component={ViewInternshipDetails}
          />
          <Route
            exact
            path="/employer-applied-candidates"
            component={EmployerAppliedCandidates}
          />
          <Route
            exact
            path="/employer-job-details"
            component={EmployerJobDetails}
          />
          <Route
            exact
            path="/employer-job-view-details"
            component={EmployerJobViewDetails}
          />
          <Route
            exact
            path="/employer-job-appliedcanditate"
            component={EmployerJobAppliedCandidate}
          />
          <Route
            exact
            path="/employer-college-connect"
            component={EmployerCollegeConnect}
          />
          <Route
            exact
            path="/employer-college-connect-students-list"
            component={EmployerCollegeStudentList}
          />
          <Route
            exact
            path="/employer-college-list"
            component={EmployerCollegeList}
          />
          <Route exact path="/employer-add-job" component={AddEmployerJobs} />
          <Route
            exact
            path="/employer-search-job"
            component={EmployerJobDetails}
          />
          <Route
            exact
            path="/employer-job-draft"
            component={EmployerJobDraft}
          />
          <Route
            exact
            path="/employer-search-candidates"
            component={EmployerSearchCandidates}
          />

          {/* Campus Flow */}
          <Route exact path="/campus-dashboard" component={CampusDashboard} />
          <Route exact path="/campus-students" component={CampusStudents} />
          <Route
            exact
            path="/campus-industry-connect"
            component={CampusIndustryConnect}
          />
          <Route
            exact
            path="/campus-industry-connect/posted-jobs"
            component={CampusIndustryConnect}
          />
          <Route
            exact
            path="/campus-faculty-development"
            component={CampusFacultyDevelopment}
          />
          <Route
            exact
            path="/campus-industry-immersion-program"
            component={CampusIndustryImmersionProgram}
          />
          <Route
            exact
            path="/campus-industry-consulting-project"
            component={CampusIndustryConsultingProject}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </AppApolloprovider>
      <GlobalStyle />
    </HashRouter>
  );
}
