import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import EmployerNavbar from 'app/components/EmployerNavbar';
import React from 'react'
import { colors } from "../../../styles/colors";
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import { PeopleAltOutlined } from "@mui/icons-material";
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import HorizontalDivider from 'app/components/HorizontalDivider';
import ViewModalProfile from '../EmployerJobsDetails/ViewModalProfile';
import images from '../../../assets/images/images'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useHistory } from 'react-router-dom';


function EmployerInternDetailsCon()
{
    const [viewModal,setViewModal]=React.useState(false)
    const handleViewModal=()=>{
        setViewModal(false)
    }
    const history=useHistory()
    return(
        <div>
      <Box sx={{mt:1,p:4,border:'solid 1px #E3E3E3'}}>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Box>
                    <Typography fontSize='16px' fontWeight='600'>Job Title</Typography>
                    <Typography color={colors.fontLight} fontSize='14px'>Software/Web Developer</Typography>
                </Box>
                <Box>
                  <Button
                    variant='outlined'
                    startIcon={<PortraitOutlinedIcon/>}
                    onClick={()=>{history.push('/employer-job-appliedcanditate')}}
                  >
                      <Typography fontSize='14px'>View Applications(15)</Typography>
                  </Button>
                  <Button
                    variant='contained'
                    startIcon={<EditOutlinedIcon/>}
                    sx={{ml:1}}
                  >
                      <Typography fontSize='14px'>Edit</Typography>
                  </Button>
                </Box>
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Box sx={{color:colors.primary,fontSize:"13px",mt:2,display:'flex'}}>   
                        <Box sx={{display:'flex',alignItems:'center'}}>
                            <LocationOnOutlinedIcon/>
                            <Typography fontSize="13px" color={colors.fontLight}marginLeft='4px'>Location</Typography>
                        </Box>
                        <Box sx={{display:'flex',alignItems:'center',marginLeft:'8px'}}>
                            <WorkHistoryOutlinedIcon/>
                            <Typography fontSize="13px" color={colors.fontLight}marginLeft='4px'>1 to 6 months</Typography>
                        </Box>
                        <Box sx={{display:'flex',alignItems:'center',marginLeft:'8px'}}>
                            <PeopleAltOutlined/>
                            <Typography fontSize="13px" color={colors.fontLight} marginLeft='4px'>12 positions</Typography>
                        </Box>
                        <Box sx={{display:'flex',alignItems:'center',marginLeft:'8px'}}>
                            <PaymentsOutlinedIcon/>
                            <Typography fontSize="13px" color={colors.fontLight} marginLeft='4px'>&#8377; 10,000 to &#8377; 15,000</Typography>
                        </Box>
                </Box>
                <Box>
                    <Box sx={{display:'flex',alignItems:'center'}}>
                        <Box sx={{color:colors.fontLighter,display:'flex',alignItems:'center'}}>
                            <EditOutlinedIcon/>
                            <Typography fontSize='13px'>Edit</Typography>
                        </Box>
                        <Box sx={{borderRadius:'50%',backgroundColor:colors.fontLighter,width:'5px',height:'5px',ml:1}}>
                        </Box>
                        <Box sx={{color:colors.fontLighter,display:'flex',alignItems:'center',ml:1}}>
                            <img src={images.shareIcon} alt="" />
                            <Typography fontSize='13px' marginLeft='4px'>Share</Typography>
                        </Box>
                        <Box sx={{borderRadius:'50%',backgroundColor:colors.fontLighter,width:'5px',height:'5px',ml:1}}>
                        </Box>
                        <Box sx={{color:colors.fontLighter,display:'flex',alignItems:'center',ml:1}} onClick={()=>{setViewModal(true)}}>
                            <VisibilityOutlinedIcon />
                            <Typography fontSize='13px' marginLeft='4px'>Views</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            <Box sx={{mt:2,width:'70%',color:colors.fontLight}}>
              <Typography fontSize='16px' color='#000000' marginBottom='8px'>Job Description</Typography>
              <Typography fontSize='14px'>
              We’re the country’s first opinion exchange and social prediction marketplace that enables learning via the discovery of truth and simultaneously providing a rewarding experience for all. Probo is an attempt to bring the thrill of trading and thus reimagine how we share opinions.
              </Typography>
              <ul>
                <li>Believers: Wars are not won by mercenaries but by true believers. Believers who walk the talk</li>
                <li>Insatiable curiosity: You keep a learner's mindset. Undeterred by past credentials, you are perennially  willing to grow as a person and professional</li>
                <li>Problem solvers: You understand people. You understand problem-solving. You know how and when to marry the tw</li>
              </ul>
              
            </Box>
            <Box sx={{color:'#E9E9E9',my:4}}>
              <hr />
            </Box>
            <Box sx={{display:'flex',alignContent:'center'}}>
              <Box sx={{width:'25%'}}>
                <Typography color={colors.fontLight} fontSize='14px'>Job Type</Typography>
                <Typography fontSize='16px' marginTop='4px'>Full Time / Remote</Typography>
              </Box>
              <Box sx={{width:'25%'}}>
                <Typography color={colors.fontLight} fontSize='14px'>Posted on</Typography>
                <Typography fontSize='16px' marginTop='4px'>15 Feb 2022</Typography>
              </Box>
              <Box sx={{width:'25%'}}>
                <Typography color={colors.fontLight} fontSize='14px'>Last date for apply</Typography>
                <Typography fontSize='16px' marginTop='4px'>20 Apr 2022</Typography>
              </Box>
              <Box sx={{width:'25%'}}>
                <Typography color={colors.fontLight} fontSize='14px'>Gender Preference</Typography>
                <Typography fontSize='16px' marginTop='4px'>No Preference</Typography>
              </Box>
            </Box>
            <Box sx={{color:'#E9E9E9',my:4}}>
              <HorizontalDivider/>
            </Box>
            <Box>
              <Typography>Salary Details</Typography>
              <Box sx={{display:'flex',alignContent:'center'}}>
                <Box sx={{width:'25%'}}>
                  <Typography color={colors.fontLight} fontSize='14px'>Salary Amount</Typography>
                  <Typography fontSize='16px' marginTop='4px'>&#8377; 15,000</Typography>
                </Box>
                <Box sx={{width:'25%'}}>
                  <Typography color={colors.fontLight} fontSize='14px'>Perks</Typography>
                  <Typography fontSize='16px' marginTop='4px'>Transport Facility</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{color:'#E9E9E9',my:4}}>
                <HorizontalDivider/>
            </Box>
            <Box>
              <Typography>Qualification Details</Typography>
              <Box sx={{display:'flex',alignContent:'center'}}>
                <Box sx={{width:'25%'}}>
                  <Typography color={colors.fontLight} fontSize='14px'>Qualification</Typography>
                  <Typography fontSize='16px' marginTop='4px'>PG</Typography>
                </Box>
                <Box sx={{width:'25%'}}>
                  <Typography color={colors.fontLight} fontSize='14px'>Year of Passing</Typography>
                  <Typography fontSize='16px' marginTop='4px'>2019-2022</Typography>
                </Box>
                <Box sx={{width:'50%'}}>
                  <Typography color={colors.fontLight} fontSize='14px'>Required Certificates</Typography>
                  <Typography fontSize='16px' marginTop='4px'>Course Completion, Conslidated Marksheet</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{mt:2}}>
              <Typography color={colors.fontLight} fontSize='14px'>Key Skill</Typography>
                <Box sx={{display:'flex',mt:2}}>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1}}>
                          <Typography fontSize='12px'>HTML</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>CSS</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>Bootstarp 5</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>JavaScript</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>.Net</Typography>
                      </Box>
                  </Box>
            </Box>

            <Box sx={{mt:2}}>
              <Typography color={colors.fontLight} fontSize='14px'>Language Known</Typography>
                <Box sx={{display:'flex',mt:2}}>
                      <Typography fontSize='16px' sx={{mr:4}}>Tamil</Typography>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1}}>
                          <Typography fontSize='12px'>HTML</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>CSS</Typography>
                      </Box>
                      <Box sx={{color:colors.primary,backgroundColor:colors.blueBackGround,width:'auto',borderRadius:'50px',px:1,ml:1}}>
                          <Typography fontSize='12px'>Bootstarp 5</Typography>
                      </Box>
                  </Box>
            </Box>     
      </Box>
      <Box sx={{textAlign:'right',mt:3}}>
        <Button
          variant='contained'
          sx={{backgroundColor:'#FF6F60'}}
        >
          Close Job
        </Button>
      </Box>
      <Modal
                open={viewModal}
                onClose={handleViewModal}
                >
               <Box 
               sx={{
                        backgroundColor:'white',
                        position:'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        py:3,
                        px:2
                        }} 
                        >
                    <Typography fontSize='18px' fontWeight='500'>Total Profile Viewed (30) </Typography>
                        <Box>
                        <ViewModalProfile/>
                        <ViewModalProfile/>
                        <ViewModalProfile/>
                        <ViewModalProfile/>
                    </Box>
                </Box>
            </Modal>
    </div>
    )
}
export function EmployerJobViewDetails(){
    return <EmployerNavbar mainComponent={<EmployerInternDetailsCon/>} />
  }