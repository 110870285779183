import { Box, Typography } from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { colors } from "../../../styles/colors";
import HorizontalDivider from "app/components/HorizontalDivider";

function ViewModalProfile() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccountCircleIcon sx={{ width: "50px", height: "50px" }} />
          <Box sx={{ ml: 1 }}>
            <Typography fontSize="14px">ProfileName</Typography>
            <Typography fontSize="12px" color={colors.fontLighter}>
              example@example.com
            </Typography>
          </Box>
        </Box>
        <Typography fontSize="12px" color={colors.fontLighter}>
          2 days ago
        </Typography>
      </Box>
      <HorizontalDivider />
    </div>
  );
}

export default ViewModalProfile;
